// FLEX CENTER
@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

// MEDIA QUERIES
@mixin phone {
  @media (max-width: 576px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 1360px) {
    @content;
  }
}

// SPAN ICON
@mixin spanIcon {
  span {
    cursor: pointer;
    border-radius: 7px;
    background-color: $dark-4;
    padding: 5px;
    transition: 0.3s all ease;
    &.active {
      background-color: $active-color;
      &:hover {
        background-color: darken($active-color, 10);
      }
    }
    &:hover {
      background-color: $dark-4;
    }
  }
}

// COMPONENT SIDEBAR
@mixin side {
  width: 90%;
  padding: 15px;
  border-bottom: 2px solid #757575;
  // background-color: $dark-4;
  // border-radius: 10px;
}

// TITLE COMPONENT
@mixin titleComponent {
  font-size: $base-font-size * 2;
  margin-bottom: 10px;
  @include desktop {
    font-size: $base-font-size * 1.4;
  }
}

@mixin subtitleComponent {
  font-size: $base-font-size * 1.3;
  @include desktop {
    font-size: $base-font-size * 1.1;
  }
}

@mixin description {
  font-size: $base-font-size * 1.1;
  @include desktop {
    font-size: $base-font-size;
  }
}
