nav {
  width: 100%;
  @include flexCenter;
  background-color: $dark-4;
  min-height: 55px;
  position: fixed;
  top: 0;
  z-index: 9;

  @include phone {
    min-height: 65px;
  }

  .container {
    justify-content: space-around;
    .logo {
      h2 {
        font-family: "Quicksand", sans-serif;
        text-transform: capitalize;
        color: #fff;
        font-size: $base-font-size * 1.35;
        font-weight: 700;
        letter-spacing: 1px;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
      @include tablet {
        order: 2;
      }
    }
    .main-icons {
      @include flexCenter;
      gap: 15px;
      line-height: 0;
      // background-color: red;

      @include spanIcon;

      @include tablet {
        display: none;
      }
    }

    .search-bar {
      position: relative;
      @include flexCenter;
      // background-color: red;
      input[type="search"] {
        width: 235px;
        height: 30px;
        color: #fff;
        border: none;
        border-radius: 5px;
        background-color: lighten($dark-4, 5);
        padding: 5px 10px;
        // height: 50px;

        &::placeholder {
          color: rgba(#fff, 0.5);
        }

        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }

        &:focus {
          outline: none;
        }

        @include tablet {
          width: 100%;
        }
      }
      label {
        line-height: 0;
        position: absolute;
        right: 3%;
        .icon {
          color: rgba(#fff, 0.5);
        }
      }

      input[type="search"]:focus ~ label {
        display: none;
      }

      input[type="search"]:not(:placeholder-shown) + label {
        display: none;
      }

      @include tablet {
        order: 3;
      }
    }

    .profile {
      // background-color: red;
      @include flexCenter;
      gap: 15px;

      .icon {
        cursor: pointer;
        transition: 0.3s all ease;

        &:hover {
          color: $active-color;
        }
      }
      a {
        line-height: 0;
      }

      @include tablet {
        order: 1;
      }
    }

    .profile > .icon {
      &:nth-child(1),
      &:nth-child(2) {
        @include tablet {
          display: none;
        }
      }
    }
  }
}
