.sideleft {
  width: 20%;
  @include flexCenter;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  margin-top: 4.5%;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 0;
  gap: 2%;
  // background-color: orange;
  // padding: 75px 0 0 0;
  // opacity: 0.7;

  @include desktop {
    margin-top: 5%;
  }

  @include tablet {
    display: none;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include side;
    .comp-sidebar {
      @include flexCenter;
      // background-color: $dark-4;
      padding: 5px;
      justify-content: flex-start;
      gap: 5%;

      .icon {
        font-size: $base-font-size * 3;
        @include desktop {
          font-size: $base-font-size * 2;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        h3 {
          font-size: $base-font-size * 1.4;
          @include desktop {
            font-size: $base-font-size * 1.1;
          }
        }
        span {
          @include description;
        }
      }

      &:first-child {
        .icon {
          color: #2bff92;
        }
      }
      &:last-child {
        .icon {
          color: $active-color;
        }
      }
      &:nth-child(2) {
        .icon {
          color: #ffd000;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: lighten($dark-4, 3);
      }
    }
  }

  .popular {
    @include side;
    display: flex;
    flex-direction: column;
    gap: 5px;
    // flex-grow: 3;

    h3 {
      @include titleComponent;
    }
    .comp-tags {
      h5 {
        @include subtitleComponent;
      }

      span {
        @include description;
      }
    }
  }

  .group {
    @include side;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @include desktop {
      gap: 8px;
    }
    // flex-grow: 2;

    h3 {
      @include titleComponent;
    }

    .comp-group {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      gap: 5px;

      img {
        width: 25px;
      }

      h5 {
        @include subtitleComponent;
      }
    }
  }
}
