@import "./sideleft/style.scss";
@import "./main/style.scss";
@import "./sideright/style.scss";
.home {
  @include flexCenter;
  justify-content: space-around;
  .post {
    position: relative;
    @include flexCenter;
    display: none;
    background: rgba($dark-4, 0.6);
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;

    &.active {
      display: flex;
    }

    &-card {
      width: 380px;
      height: 320px;
      background-color: $dark-4;
      border-radius: 10px;

      @include phone {
        width: 320px;
        height: 320px;
      }

      .comp-post {
        position: relative;
        // padding: 30px;
        .close {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          cursor: pointer;
          border-radius: 50%;
          font-size: $base-font-size;
          position: absolute;
          padding: 5px;
          width: 20px;
          height: 20px;
          right: 3%;
          top: 3%;
          background-color: #3a3b3c;

          &::before {
            content: "";
            width: 95%;
            height: 1px;
            background-color: #fff;
            transform-origin: 0;
            transform: rotate(45deg) translate(0, -2px);
          }
          &::after {
            content: "";
            width: 95%;
            height: 1px;
            background-color: #fff;
            transform-origin: 0;
            transform: rotate(-45deg) translate(0, 2px);
          }
        }

        h4 {
          font-size: $base-font-size * 1.5;
          text-align: center;
          line-height: 50px;
        }

        hr {
          border: none;
          background-color: #fff;
          height: 1px;
          opacity: 0.3;
        }

        form {
          margin-top: 25px;
          padding: 10px 10px 0 10px;

          .user {
            display: flex;
            margin-bottom: 10px;
          }

          h5 {
            // margin-top: 30px;
            margin-left: 10px;
          }

          textarea {
            padding: 10px;
            width: 100%;
            height: 150px;
            background-color: transparent;
            color: #fff;
            border-radius: 5px;
          }

          button {
            width: 100%;
            margin-top: 15px;
            padding: $base-font-size * 0.5 $base-font-size * 1.2;
            border: none;
            border-radius: 5px;
            background-color: lighten($dark-4, 10);
            color: #fff;
            cursor: pointer;
            transition: 0.25s all ease;

            &:hover {
              background-color: $active-color;
            }
          }
        }
      }
    }
  }

  @include phone {
    margin-top: 65px;
  }
}
