.login {
  font-family: "Quicksand", sans-serif;
  @include flexCenter;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;

  .comp-login {
    padding: 20px;
    background-color: transparent;
    @include flexCenter;
    flex-direction: column;
    gap: 20px;
    .login-with {
      @include flexCenter;
      flex-direction: column;
      gap: 12px;
      button {
        cursor: pointer;
        background-color: transparent;
        color: #fff;
        border: 3px solid darken($dark-4, 2);
        @include flexCenter;
        border-radius: 10px;
        width: 300px;
        padding: 8px;
        gap: 12px;
        font-size: $base-font-size * 1.2;
        .log {
          font-size: $base-font-size * 1.5;
        }
      }
    }

    h2 {
      font-size: $base-font-size * 3.5;
      letter-spacing: 0.5px;
    }

    form {
      @include flexCenter;
      flex-direction: column;
      gap: 12px;
      input {
        background-color: transparent;
        color: #fff;
        border: 3px solid darken($dark-4, 2);
        border-radius: 10px;
        padding: 10px;
        width: 300px;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #fff;
        }
      }

      button {
        background-color: $active-color;
        cursor: pointer;
        color: #fff;
        font-weight: 700;
        font-size: $base-font-size * 1.3;
        border: none;
        border-radius: 10px;
        width: 100%;
        padding: 10px;
        box-shadow: 0 5px lighten($active-color, 10);
        transition: 0.35s all ease;

        &:hover {
          background-color: lighten($active-color, 10);
          box-shadow: 0 5px $active-color;
        }
      }
    }

    .link {
      color: #fff;
      text-decoration: none;
      margin: 0 10px;

      &:hover {
        color: $active-color;
      }
    }
  }
}
