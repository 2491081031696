.sideright {
  @include flexCenter;
  flex-direction: column;
  justify-content: flex-start;
  width: 20%;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: 6%;
  z-index: 0;

  @include tablet {
    display: none;
  }

  h3 {
    font-size: $base-font-size * 1.3;
    margin-bottom: 15px;
    letter-spacing: 0.5px;
  }

  .meetup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include side;
    gap: 4%;

    h3 {
      font-size: $base-font-size * 1.2;
      @include desktop {
        font-size: $base-font-size;
      }
    }
    .comp-meetup {
      padding: 5px;
      display: flex;
      gap: 3%;
      .left {
        @include flexCenter;
        flex-direction: column;
        border-radius: 7px;
        padding: 15px 7px;
        background-color: lighten($dark-4, 10);
        gap: 10px;

        h3 {
          background-color: red;
          line-height: 0;
        }

        h4 {
          line-height: 0;
          background-color: blue;
        }
      }

      .right {
        h5 {
          font-size: $base-font-size;
          @include desktop {
            font-size: $base-font-size;
          }
        }

        span {
          font-size: $base-font-size * 0.8;
        }
      }
    }
  }

  .podcast {
    @include side;
    display: flex;
    flex-direction: column;
    gap: 15px;
    // flex-grow: 2;

    h3 {
      font-size: $base-font-size * 1.2;
      @include desktop {
        font-size: $base-font-size;
      }
    }

    .comp-podcast {
      display: flex;
      gap: 7px;

      img {
        width: 30px;
      }

      &-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 13px;
        h5 {
          line-height: 0;
          font-size: $base-font-size;
        }

        span {
          line-height: 0;
          font-size: $base-font-size * 0.8;
        }
      }
    }
  }
}
