// COLORs
$bg-color: #383e46;
$dark-3: #262d34;
$dark-4: #282b2f;
$secondary-dark-3: #97989d;
$secondary-dark-4: #858ead;
$secondary-color: #97989d;
$active-color: #386ae8;
$base-text-color: #000000;

// FONTs
$base-font-size: 0.75rem;
$font-weights: (
  "regular": 400,
  "medium": 500,
  "bold": 700,
);
