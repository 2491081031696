@import url("https://fonts.googleapis.com/css2?family=Paytone+One&family=Roboto+Condensed:wght@400;700&family=Quicksand:wght@300;400;500;600;700&display=swap");
@function fontWeight($weight-name) {
  @return map-get($font-weights, $weight-name);
}

body {
  background-color: $bg-color;
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  overflow-x: hidden;
  // min-height: 3000px;
}
// CSS RESET
* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  //   font-family: "Rubik", sans-serif;
  // font-family: Arial, Helvetica, sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

// ICON
.icon {
  color: #fff;
  font-size: $base-font-size * 1.55;
}
