.main {
  position: absolute;
  top: 55px;
  padding: 20px 0;
  @include flexCenter;
  width: 60%;
  @include tablet {
    padding-bottom: 80px;
    width: 100%;
  }

  .comp-post {
    @include side;
    @include flexCenter;
    border: none;
    border-radius: 10px;
    gap: 3%;
    width: 100%;
    padding: 10px 5px;
    background-color: lighten($dark-4, 3);
    button {
      width: 90%;
      cursor: pointer;
      text-align: left;
      font-size: $base-font-size;
      background-color: lighten($dark-4, 5);
      color: rgba(#fff, 0.5);
      border: none;
      border-radius: 5px;
      padding: 10px 12px;
      letter-spacing: 0.7px;
      transition: 0.25s all ease;

      @include tablet {
        font-size: $base-font-size * 0.9;
      }

      &:hover {
        background-color: lighten($dark-4, 7);
      }
    }
    .icon {
      font-size: $base-font-size * 2.3;
    }

    @include tablet {
      width: 95%;
    }
  }
}
