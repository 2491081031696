footer {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 9;
  background-color: $dark-3;

  @include tablet {
    @include flexCenter;
    width: 100%;
    min-height: 75px;
  }

  .container {
    justify-content: space-around;
    line-height: 0;

    @include spanIcon;
    .icon {
      font-size: $base-font-size * 2;
    }
  }
}
